import React from "react";
import AppTheme from '../theme/AppTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from '@mui/material';
import { jwtDecode } from "jwt-decode";
import logo from '../theme/ressources/studioBarberlogoNew.png';

const HomePage = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const isTokenValid = (token) => {
    if (!token) return false;

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;


      if (decodedToken.exp < currentTime) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  if (token && isTokenValid(token)) {
    navigate("/userapp");
  }

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <div style={styles.container}>
        <img
          src={logo}
          alt="Studio Barber Logo"
          style={{ width: "25rem", height: "auto",}}
        />
        <div>
          <Button>
            <Link to="/signin" style={{ textDecoration: "none", fontFamily: "'Space Mono', monospace", color: "inherit" }}>
              Se connecter
            </Link>
          </Button>
          <Button>
            <Link to="/signup" style={{ textDecoration: "none", fontFamily: "'Space Mono', monospace", color: "inherit" }}>
              S'inscrire
            </Link>
          </Button>
        </div>
      </div>
    </AppTheme>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  button: {
    margin: "10px",
    padding: "10px 20px",
    fontSize: "16px",
  },
};

export default HomePage;
