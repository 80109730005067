import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QrScanner from 'react-qr-scanner';
import Button from '@mui/material/Button';
import background from "../theme/ressources/background.jpg";
import logo from '../theme/ressources/studioBarberlogoNew.png';


function QRScan() {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [showScanner, setShowScanner] = useState(true);

  const handleOnClickScanner = () => {
    setUserInfo(null)
    setShowScanner(true);
  };

  const handleScan = async (result) => {
    if (result) {
      const scannedCode = result.text;
      setShowScanner(false);
      console.log(scannedCode);

      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`/api/qrcode/get-user-infos-qr-code?userId=${scannedCode}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données.');
        }

        const result = await response.json();

        setUserInfo({
          imageBase64: result.entity.profilePicture,
          firstName: result.entity.firstName,
          lastName: result.entity.lastName,
          subscriptionState: result.entity.isActif

        });
        setError(null);
      } catch (err) {
        console.error(err);
        setError("Impossible de récupérer les informations. Veuillez réessayer.");
        setUserInfo(null);
      }
    }
  };

  const handleError = (error) => {
    console.error(error);
    setError("Erreur avec le scanner de QR Code.");
  };

  return (
    <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ width: "100%", backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", position: "relative", top: 0 }}>
      <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 10,
          }}
        >
          <img src={logo} alt="logo" style={{ width: "10rem", height: "4rem" }} />
        </Box>
        <Typography variant="h5" sx={{ color: "#2A3240", fontWeight: "bold", fontFamily: "'Space Mono', monospace", textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)" }}>
          QR Code scanner
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "30rem",
          paddingRight: "5rem",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid lightgray',
            borderRadius: 2,
            padding: "1rem",
            backgroundColor: "#e683ba",
            width: 'fit-content',
            height: 'fit-content',
            maxWidth: '100%',
            overflow: 'hidden',
            margin: 'auto', // Centre horizontalement
            marginTop: '1rem',
            marginLeft: '2rem', // Ajoute un espacement à gauche
            marginRight: '2rem', // Ajoute un espacement à droite
          }}
        >
          {showScanner ?
            (
              <QrScanner
                facingmode={"environment"}
                constraints={{
                  video: { facingMode: "environment" }
                }}
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{
                  width: '300px', // Vous pouvez ajuster cette taille
                  height: '300px',
                  objectFit: 'cover', // Maintient le contenu dans les limites
                }}
              />
            )
            :
            (
              <Button variant="contained" onClick={handleOnClickScanner}> Scanner à nouveau </Button>
            )

          }
          {error && (
            <Typography color="error">{error}</Typography>
          )}
          {userInfo && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Informations utilisateur</Typography>
              <img
                src={`data:image/png;base64,${userInfo.imageBase64}`}
                alt="QR Code"
                style={{ maxWidth: '100%', borderRadius: '8px' }}
              />
              <Typography>Nom : {userInfo.lastName}</Typography>
              <Typography>Prénom : {userInfo.firstName}</Typography>
              <Typography
                sx={{
                  color: userInfo.subscriptionState ? 'green' : 'red',
                }}
              >
                Statue de l'abonnement : {userInfo.subscriptionState ? 'Actif' : 'Non actif'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default QRScan;
