import React from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import background from "../theme/ressources/background.jpg";
import Footer from './Footer';
import logo from '../theme/ressources/studioBarberlogoNew.png';

function PriseAbonnement() {
  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "50%",
          borderBottomRightRadius: "50%",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          top: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 10,
          }}
        >
          <img src={logo} alt="logo" style={{ width: "10rem", height: "4rem" }} />
        </Box>
        <Typography
          variant="h5"
          sx={{
            color: "#2A3240",
            fontWeight: "bold",
            fontFamily: "'Space Mono', monospace",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
          }}
        >
          Abonnement nominatif à 19.99€/mois.
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
        }}
      >
        <Container
          maxWidth="sm"
          style={{
            textAlign: "center",
            padding: "2rem",
            backgroundColor: "#f5f5f5",
            borderRadius: "1rem",
          }}
        >
          <Typography
            variant="h6"
            paragraph
            style={{ color: "#555", marginTop: "1rem", fontFamily: "'Space Mono', monospace" }}
          >
            L’abonnement te donne la possibilité de te couper les cheveux un
            nombre de fois illimité chaque mois en prenant rendez-vous sur
            l’application Studio Barber.
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#777", marginBottom: "2rem", fontFamily: "'Space Mono', monospace" }}
          >
            (Prestations comprises dans l’offre : Coupe, coupe + barbe, barbe)
          </Typography>
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#e683ba",
                color: "#ffffff",
                fontWeight: "bold",
                fontFamily: "'Space Mono', monospace",
                textTransform: "none",
                padding: "12px 20px",
                borderRadius: "8px",
              }}
            >
              <Link
                to="/userapp/checkout"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Passer au paiement
              </Link>
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}

export default PriseAbonnement;
