import React, { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import Box from '@mui/material/Box';

const stripePromise = loadStripe('pk_live_51QRtehIRYr4aJ4VXW36YTufAdARLymMjiqFg5jAsmaRTr5vSCkglGJU40UKV3zCjNabFBCd26YR7PoC3JLf1GxbT00TYyR9Lib');

const FormulairePaiement = () => {

  const fetchClientSecret = useCallback(() => {
    const token = localStorage.getItem("token");
    return fetch(`/api/payment/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ id: 'prod_RSCrsf4Jq4OSFV'}), 
    })
      .then((res) => res.json())
      .then((data) => data.entity.clientSecret);
  });

  const options = { fetchClientSecret };

  return (
    <Box id="checkout" sx={{
      backgroundColor: "#525f7f",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }} >
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Box>
  );
};

export default FormulairePaiement;
