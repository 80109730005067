import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import background from "../theme/ressources/background.jpg";
import Stack from '@mui/material/Stack';
import theme from '../theme/ThemePerso';
import { ThemeProvider } from '@mui/material/styles';
import Footer from './Footer';
import logo from '../theme/ressources/studioBarberlogoNew.png';

const SignUpContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Centré verticalement
  width: '100%', // Prend toute la largeur disponible
  maxWidth: '25rem', // Limite maximale (équivaut à 400px)
  margin: '0 auto', // Centré horizontalement
  padding: '2rem', // Espaces intérieurs
  minHeight: '100%',
  gap: '1.5rem',
}));

export default function EditProfile() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [numero, setNumero] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const token = localStorage.getItem("token");
  
  useEffect(() => {
    
    async function fetchUserData() {
      try {
        const response = await fetch(`/api/user/get-user-infos`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const userData = await response.json();
          setFirstName(userData.entity.firstName);
          setLastName(userData.entity.lastName);
          setEmail(userData.entity.email);
          setNumero(userData.entity.phone);
        } else {
          console.error('Erreur lors de la récupération des données utilisateur.');
        }
      } catch (error) {
        console.error('Erreur réseau ou serveur :', error);
      }
    }
    fetchUserData();
  }, []);

  const handleManageBilling = async () => {
    try {

      const data = await fetch(`/api/user/create-customer-portal-session`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response  = await data.json();
      if (response.entity) {
        console.log(response.entity);
        window.location.href = response.entity; 
      }
    } catch (error) {
      console.error('Erreur lors de la redirection :', error);
    }
  };

  const validateInputs = () => {
    let isValid = true;
    const errors = {};

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Veuillez entrer un email valide.';
      isValid = false;
    }

    if (numero.length !== 10) {
      errors.numero = 'Veuillez entrer un numéro valide.';
      isValid = false;
    }

    if (password && password.length < 10) {
      errors.password = 'Le mot de passe doit comprendre au moins 10 caractères.';
      isValid = false;
    }

    setErrorMessages(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateInputs()) {
      return;
    }
    try {
      const response = await fetch(`/api/user/modify-user-informations`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: numero,
          password: password || undefined,
        }),
      });

      if (response.ok) {
        alert('Profil mis à jour avec succès.');
        navigate('/userapp');
      } else {
        const errorData = await response.json();
        console.error('Erreur lors de la mise à jour :', errorData);
        alert('Erreur lors de la mise à jour. Veuillez vérifier vos informations.');
      }
    } catch (error) {
      console.error('Erreur réseau ou serveur :', error);
      alert('Erreur de connexion. Veuillez réessayer plus tard.');
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "50%",
          borderBottomRightRadius: "50%",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          fontFamily: "'Space Mono', monospace",
          top: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 10,
          }}
        >
          <img src={logo} alt="logo" style={{ width: "10rem", height: "4rem" }} />
        </Box>
        <Typography
          variant="h4"
          sx={{
            color: "#2A3240",
            fontWeight: "bold",
            fontFamily: "'Space Mono', monospace",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
          }}
        >
          Modifier mes données
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "30rem",
          paddingRight: "5rem",
          boxSizing: "border-box",
        }}
      >

        <form onSubmit={handleSubmit}>
          <ThemeProvider theme={theme}>
            <SignUpContainer>
             <FormControl>
                <TextField
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={!!errorMessages.firstName}
                  helperText={errorMessages.firstName}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  error={!!errorMessages.lastName}
                  helperText={errorMessages.lastName}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="email"
                  value={email}
                  disabled
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!errorMessages.email}
                  helperText={errorMessages.email}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="numero"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                  error={!!errorMessages.numero}
                  helperText={errorMessages.numero}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="password"
                  label='Nouveau mot de passe ?'
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!errorMessages.password}
                  helperText={errorMessages.password}
                />
              </FormControl>
              <Button type="submit" variant="contained" sx={{
                alignSelf: 'center', marginTop: '1rem', backgroundColor: "#e683ba",
                color: "#ffffff",
                fontWeight: "bold",
                fontFamily: "'Space Mono', monospace",
                textTransform: "none"
              }}>
                Confirmer
              </Button>
            </SignUpContainer>
          </ThemeProvider>
        </form>
      </Box>
      <Box>

        <Button onClick={handleManageBilling} variant="contained" sx={{
          alignSelf: 'center', marginTop: '1rem', border: "2px solid #e683ba" ,backgroundColor: "#ffffff",
          color: "#f051f8",fontWeight: "bold", fontFamily: "'Space Mono', monospace", textTransform: "none" }}>
            Resilier Abonnement
      
        </Button>

      </Box>
      <Footer />
    </div>
  );
}
