import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    console.log(`Session ID : ${sessionId}`);

    fetch(`/api/payment/session-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sessionId: `${sessionId}` }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Erreur HTTP : ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setStatus(data.entity.status);
        setCustomerEmail(data.entity.customer_email);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération du statut de la session :', error);
      });
  }, []);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <Box
        id="success"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: '#f9f9f9',
          fontFamily: "'Space Mono', monospace",
          textAlign: 'center',
          padding: 3,
        }}
      >
        <Typography variant="h5" gutterBottom>
          🎉 Abonnement pris avec succès !
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Merci pour votre confiance ! Un email de confirmation sera envoyé à <strong>{customerEmail}</strong>.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/"
          sx={{ textTransform: 'none', padding: '10px 20px', fontFamily: "'Space Mono', monospace", fontSize: '16px' }}
        >
          Retour à l'accueil
        </Button>
      </Box>
    );
  }

  return null; // Si le statut n'est pas encore disponible, on n'affiche rien.
};

export default Return;
