import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import background from "../theme/ressources/background.jpg";
import LogoutButton from "../composant/LogoutButton";
import Footer from './Footer';
import logo from '../theme/ressources/studioBarberlogoNew.png';

function Abonnement() {

  const buttonStyle = {
    width: "20rem", // Largeur uniforme pour tous les boutons
    textAlign: "center",
    backgroundColor: "#e683ba",
    fontFamily: "'Space Mono', monospace",
    color: "#ffffff",
    fontWeight: "bold",
    padding: "12px 20px",
    borderRadius: "8px",
    textTransform: "none",
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "50%",
          borderBottomRightRadius: "50%",
          fontFamily: "'Space Mono', monospace",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          top: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 10,
          }}
        >
          <img src={logo} alt="logo" style={{ width: "10rem", height: "4rem" }} />
        </Box>
        <Typography
          variant="h5"
          sx={{
            color: "#2A3240",
            fontWeight: "bold",
            fontFamily: "'Space Mono', monospace",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
          }}
        >
          Modifier vos coordonées
        </Typography>

      </Box>
      <Box
        sx={{
          marginTop: "5rem",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
        }}
      >
        <Button
          component={Link}
          to="/userapp/prise-abonnement"
          variant="contained"
          color="primary"
          size="large"
          sx={buttonStyle}
        >
          Prendre un abonnement
        </Button>
        <Button
          component={Link}
          to="/userapp/qr-code"
          variant="contained"
          color="primary"
          size="large"
          sx={buttonStyle}
        >
          Voir QR code
        </Button>
        <Button
          component={Link}
          to="/userapp/modification-user"
          variant="contained"
          color="primary"
          size="large"
          sx={buttonStyle}
        >
          Gère ton abonnement
        </Button>
      </Box>
      <Box sx={{ marginTop: "4rem" }} >
        <LogoutButton />
      </Box>
      <Footer />
    </div>
  );
}

export default Abonnement;
