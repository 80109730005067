import React from "react";
import { Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer
      style={{
        marginTop: "auto",
        width: "100%",
        backgroundColor: "#333",
        color: "#fff",
        textAlign: "center",
        padding: "1rem 0",
      }}
    >
      <Typography variant="body2" sx={{ fontSize: "0.600rem" }}>
        <a
          href="/CharteConfidentialite.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff", fontFamily: "'Space Mono', monospace", textDecoration: "none" }}
        >
          Chartes de confidentialité
        </a>{"    "}
        |{"    "}
        <a
          href="/MentionLegale.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff", fontFamily: "'Space Mono', monospace", textDecoration: "none" }}
        >
          Mentions légales
        </a>
      </Typography>
    </footer>
  );
};

export default Footer;
