import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../theme/ressources/studioBarberlogoNew.png';
import background from "../theme/ressources/background.jpg";
import Footer from './Footer';

function QRCode() {
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`/api/qrcode/get-qr-code`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.log(response);
          throw new Error("Erreur lors de la récupération du QR Code.");
        }

        const data = await response.json();
        setQrCodeBase64(data.entity);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQRCode();
  }, []);

  return (
    <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ width: "100%", backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", height: "250px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", position: "relative", top: 0 }}>
      <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 10,
          }}
        >
          <img src={logo} alt="logo" style={{ width: "10rem", height: "4rem" }} />
        </Box>
        <Typography variant="h5" sx={{ color: "#2A3240", fontWeight: "bold", fontFamily: "'Space Mono', monospace", textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)" }}>
          QR Code
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "30rem",
          paddingRight: "5rem",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <img src={logo} alt="logo" width='400rem' height='200rem' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',// Réduction du padding
            border: '1px solid lightgray',
            borderRadius: 2,
            marginLeft: '5rem',
            padding: "2rem",
            height: '10rem',
            width: '10rem', // Limite la largeur du cadre
            backgroundColor: "#e683ba"
          }}
        >
          {loading && <Typography>Chargement du QR code...</Typography>}
          {error && <Typography color="error">{error}</Typography>}
          {qrCodeBase64 && (
            <img
              src={`data:image/png;base64,${qrCodeBase64}`}
              alt="QR Code"
              style={{ width: '10rem', height: '10rem', objectFit: 'contain' }}
            />
          )}
        </Box>
      </Box>
      <Footer />
    </div>
  );
}

export default QRCode;
