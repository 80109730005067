import React from "react";
import {Button, Container, Box } from '@mui/material';
import Footer from './Footer';

function GestionAbonnement({ setActiveTab }) {
  return (
    <div>
      <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '2rem' }}>
        <Box display="flex" flexDirection="column" gap={2} mt={4}>
          <Button variant="outlined" color="primary" size="large" onClick={() => setActiveTab("Abonnement")}>
            Gère ton abonnement
          </Button>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default GestionAbonnement;