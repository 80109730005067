import React, { useState, useRef } from 'react';
import { Box, IconButton, Avatar, Typography, Modal, Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import background from "../theme/ressources/background.jpg";
import theme from '../theme/ThemePerso';
import { ThemeProvider } from '@mui/material/styles';
import BackButton from "../composant/BackButton";
import TermsModal from './TermsModal.js';
import Footer from './Footer';
import logo from '../theme/ressources/studioBarberlogoNew.png';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  minWidth: '300px',
  minHeight: '150px',
  p: 4,
};


function RegistrationSuccessModal({ open, navigateToSignin }) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          Inscription Réussie
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Votre inscription a été réussie ! Veuillez valider votre adresse email pour pouvoir vous connecter.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={navigateToSignin}
          sx={{ mt: 3, backgroundColor: '#e683ba' }}
        >
          Aller à la page de connexion
        </Button>
      </Box>
    </Modal>
  );
}

const SignUpContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '25rem',
  margin: '0 auto',
  padding: '2rem',
  minHeight: '100%',
}));

export default function SignUp(props) {
  const [usernameError, setUserNameError] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [numeroError, setNumeroError] = useState(false);
  const [numeroErrorMessage, setNumeroErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const navigateToSignin = () => {
    setOpenModal(false);
    navigate('/signin');
  };


  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleAcceptTerms = () => {
    setHasAcceptedTerms(true);
    setTermsChecked(true);
    setOpenTermsModal(false);
  };

  const openWebcam = () => {
    setOpenCamera(true);
    setProfilePicture(null);
  };

  const capture = () => {
    const image = webcamRef.current.getScreenshot();
    if (image) {
      setCapturedImage(image);
    }
  };

  const confirmPhoto = () => {
    if (capturedImage) {
      const byteCharacters = atob(capturedImage.split(',')[1]);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      setProfilePicture(byteArray);
    }
    setOpenCamera(false);
  };

  const retakePhoto = () => {
    setProfilePicture(null);
    setCapturedImage(null);
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');
    const username = document.getElementById('username');
    const firstName = document.getElementById('firstName');
    const lastName = document.getElementById('lastName');
    const numero = document.getElementById('numero');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Veuillez entrer un email valide.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (numero.value.length !== 10) {
      setNumeroError(true);
      setNumeroErrorMessage('Veuillez entrer un numéro valide.');
      isValid = false;
    } else {
      setNumeroError(false);
      setNumeroErrorMessage('');
    }

    if (!password.value || password.value.length < 10) {
      setPasswordError(true);
      setPasswordErrorMessage('Le mot de passe doit comprendre au moins 10 caractères.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!username.value || username.value.length < 1) {
      setUserNameError(true);
      setUsernameErrorMessage("Veuillez entrez un nom d'utilisateur.");
      isValid = false;
    } else {
      setUserNameError(false);
      setUsernameErrorMessage('');
    }

    if (!firstName.value || firstName.value.length < 1) {
      setFirstNameError(true);
      setFirstNameErrorMessage('Veuillez entrez un prénom.');
      isValid = false;
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage('');
    }
    if (!lastName.value || lastName.value.length < 1) {
      setLastNameError(true);
      setLastNameErrorMessage('Veuillez entrez un nom.');
      isValid = false;
    } else {
      setLastNameError(false);
      setLastNameErrorMessage('');
    }

    if (!termsChecked) {
      setTermsError(true);
      isValid = false;
    } else {
      setTermsError(false);
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (!profilePicture) {
      alert('Veuillez ajouter une photo de profil avant de continuer.');
      return;
    }

    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const lastName = data.get('lastName');
    const firstName = data.get('firstName');
    const email = data.get('email');
    const password = data.get('password');
    const numero = data.get('numero');

    try {
      const response = await fetch(`/api/inscription/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          phone: numero,
          profilePicture: Array.from(profilePicture),
        }),
      });

      const errorData = await response.json();
      if (errorData.status == 409) {
        setUserNameError(true);
        setUsernameErrorMessage('Nom d\'utilisateur déjà utilisé.');
        setEmailError(true);
        setEmailErrorMessage('Email déjà utilisé.');
      }
      else if (errorData.status == 500) {
        alert('Erreur de connexion. Veuillez réessayer plus tard.');
      }
      else if (errorData.status == 201) {
        setOpenModal(true);
      }
      else {
        alert('Erreur lors de l\'inscription. Veuillez vérifier vos informations.');
      }
      return;
    } catch (error) {
      setOpenModal(false);
      console.error('Erreur réseau ou serveur :', error);
      alert('Erreur de connexion. Veuillez réessayer plus tard.');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        fontFamily: "'Space Mono', monospace",
      }}
    >

      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderBottomLeftRadius: "50%",
          borderBottomRightRadius: "50%",
          height: "350px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 10,
          }}
        >
          <BackButton
            variant="contained"
            sx={{
              backgroundColor: "#e683ba",
              color: "#fff",
              '&:hover': {
                backgroundColor: "#e683ba",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 10,
          }}
        >
          <img src={logo} alt="logo" style={{ width: "10rem", height: "4rem" }} />
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "10rem",
            height: "10rem",
            borderRadius: "50%",
            overflow: "visible",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            border: profilePicture ? "none" : "2px solid gray",
            backgroundColor: profilePicture ? "transparent" : "#f0f0f0",
          }}
        >
          {(capturedImage && !openCamera) ? (
            <Avatar
              src={capturedImage}
              alt="Profile Picture"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{fontFamily: "'Space Mono', monospace"}}>
              Ajouter une photo
            </Typography>
          )}

          <IconButton
            onClick={openWebcam}
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "#ffffff",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              borderRadius: "50%",
              p: "5px",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            <PhotoCameraIcon color="primary" />
          </IconButton>
        </Box>
        <Box sx={{ paddingTop: "1rem" }}>
          <Typography
            variant="caption"
            sx={{
              textAlign: "center",
              marginTop: "0.5rem", // Espacement entre la photo et le texte
              color: "#ffffff",
              fontSize: "0.8rem",
              fontFamily: "'Space Mono', monospace",
              fontWeight: 600,
            }}
          >
            Votre photo doit obligatoirement <br /> vous représenter de manière claire.
          </Typography>
        </Box>
        <Dialog open={openCamera} onClose={() => setOpenCamera(false)}>
          <DialogTitle>Prendre une photo</DialogTitle>
          <DialogContent>
            {capturedImage ? (
              <img src={capturedImage} alt="Aperçu" style={{ width: '100%' }} />
            ) : (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width="100%"
                videoConstraints={{ facingMode: 'user' }}
                mirrored={true}
              />
            )}
          </DialogContent>
          <DialogActions>
            {capturedImage ? (
              <>
                <Button onClick={retakePhoto} color="secondary">Reprendre</Button>
                <Button onClick={confirmPhoto} color="primary">Confirmer</Button>
              </>
            ) : (
              <Button onClick={capture} color="primary">Capturer</Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "30rem",
          paddingRight: "5rem",
          boxSizing: "border-box",
        }}
      >
        <form onSubmit={handleSubmit}>
          <ThemeProvider theme={theme}>
            <SignUpContainer>
              <Typography variant="h4" sx={{ textAlign: "left", fontFamily: "'Space Mono', monospace", fontWeight: 400 }}>Inscris-toi</Typography>
              <TextField fullWidth id="username" name="username" label="Nom d'utilisateur" margin="normal" error={usernameError} helperText={usernameErrorMessage} />
              <TextField fullWidth id="firstName" name="firstName" label="Prénom" margin="normal" error={firstNameError} helperText={firstNameErrorMessage} />
              <TextField fullWidth id="lastName" name="lastName" label="Nom" margin="normal" error={lastNameError} helperText={lastNameErrorMessage} />
              <TextField fullWidth id="email" name="email" label="Email" margin="normal" error={emailError} helperText={emailErrorMessage} />
              <TextField fullWidth id="numero" name="numero" label="Numéro de téléphone" margin="normal" error={numeroError} helperText={numeroErrorMessage} />
              <TextField fullWidth id="password" name="password" label="Mot de passe" type="password" margin="normal" error={passwordError} helperText={passwordErrorMessage} />
              <FormControl component="fieldset" sx={{ mt: 2, alignItems: 'flex-start' }}>
                <Stack direction="row" alignItems="center" sx={{ justifyContent: 'flex-start', width: '100%' }}>
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsChecked}
                    onChange={() => setTermsChecked(!termsChecked)}
                    disabled={!hasAcceptedTerms}
                    style={{ marginRight: '8px' }}
                  />
                  <FormLabel htmlFor="terms" sx={{fontFamily: "'Space Mono', monospace", fontSize: "0.750rem"}}>
                    J'ai pris connaissance des{' '}
                    <Link href="#" onClick={handleOpenTermsModal}>conditions générales d'utilisation</Link>.
                  </FormLabel>
                </Stack>
                {termsError && (
                  <Typography color="error" variant="body2">
                    Vous devez accepter les conditions générales.
                  </Typography>
                )}
              </FormControl>
              <FormControl component="fieldset" sx={{ mt: 1, alignItems: 'flex-start', paddingBottom: "0.5rem" }}>
                <Stack direction="row" alignItems="center" sx={{ justifyContent: 'flex-start', width: '100%' }}>
                  <input
                    type="checkbox"
                    id="newsletter"
                    checked={newsletterChecked}
                    onChange={() => setNewsletterChecked(!newsletterChecked)}
                    style={{ marginRight: '8px' }}
                  />
                  <FormLabel htmlFor="newsletter" sx={{fontFamily: "'Space Mono', monospace", fontSize: "0.750rem"}}>J'accepte de recevoir la newsletter.</FormLabel>
                </Stack>
              </FormControl>


              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ backgroundColor: '#e683ba', fontFamily: "'Space Mono', monospace", textTransform: "none" }} >
                S'inscrire
              </Button>
              <Typography variant="body2" sx={{ paddingLeft: "2rem", paddingTop: "0.5rem", fontFamily: "'Space Mono', monospace", }} >
                <Link href="/signin" sx={{
                  textDecoration: "none", color: "inherit", // Utilise la couleur par défaut du texte, ou spécifiez-en une
                  '&:hover': {
                    textDecoration: "underline",
                  },
                }}>Déjà un compte ? Connectez-vous</Link>
              </Typography>
            </SignUpContainer>
          </ThemeProvider>
        </form>
      </Box>
      <RegistrationSuccessModal
        open={openModal}
        navigateToSignin={navigateToSignin}
      />
      <Box
        sx={{
          width: "100%",
          maxWidth: "30rem",
          paddingRight: "5rem",
          boxSizing: "border-box",
        }}
      >
        <TermsModal
          open={openTermsModal}
          onClose={handleCloseTermsModal}
          onAccept={handleAcceptTerms}
        />
      </Box>
      <Footer />
    </Box>
  );
}
